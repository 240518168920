import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import LoadingButton from "@material-ui/lab/LoadingButton";
import MobileDateTimePicker from "@material-ui/lab/MobileDateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getTime } from "date-fns";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import "dayjs/locale/pt-br"; // Importa o idioma PT-BR
import * as Yup from "yup";
import {
  Event,
  EventColor,
  eventColors,
  eventColorsJson,
} from "../types/event";
import {
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  Grid,
  InputLabel,
} from "@material-ui/core";
import { useCalendar } from "../../core/contexts/CalendarProvider";
import {
  ClientContactsType,
  ClientsType,
  getClientContacts,
} from "../../admin/hooks/useClients";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { findAllCandidates } from "../../admin/hooks/useCandidate";
import formatDateNull from "../../core/utils/formatDateNull";
import { Pessoal } from "../../admin/hooks/@types/candidateTypes";
import {
  createTheme,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  getSituationAg,
  getTipoAgC,
  getTipoAgR,
  TipoAgType,
  TipoSituationType,
} from "../../admin/hooks/useUtils";
import { useFunctions } from "../../core/contexts/FunctionsProvider";
import compareClients from "../../core/utils/compareClients";
import compareContactsAg from "../../core/utils/compareContactsAg";
import { ptBR as dataGridPtBr } from "@mui/x-data-grid";
import { ptBR as corePtBr } from "@mui/material/locale";
import { ThemeProvider } from "@mui/styles";
import { LocalizationProvider } from "@material-ui/lab";

type EventDialogProps = {
  onAdd: (event: Partial<Event>) => void;
  onClose: () => void;
  onDelete: (eventId: string) => void;
  onUpdate: (event: Event) => void;
  open: boolean;
  processing: boolean;
  event?: Event;
  hisCanId?: number;
  hisId?: number;
};

type EventFormValues = {
  title: string;
  description?: string;
  start: Date;
  end: Date;
  color?: EventColor;
  type?: string;
  event_type: number | undefined;
  event_situation: number | undefined;
  codchave: Pessoal | undefined;
  contact_id: number | undefined;
};

const EventDialog = ({
  onAdd,
  onClose,
  onDelete,
  onUpdate,
  open,
  processing,
  event,
  hisCanId,
  hisId,
}: EventDialogProps) => {
  const { t } = useTranslation();

  const { codChave, hasPattern } = useCalendar();
  const [openAsyncContacts, setOpenAsyncContacts] = useState(false);
  const [openAsyncCLients, setOpenAsyncClients] = useState(false);

  const editMode = Boolean(event && event.id);

  const { clientsSys } = useFunctions();

  const convertFormValues = (values: Event): Partial<Event> => {
    return {
      ...values,
      start: getTime(values.start),
      end: getTime(values.end),
      type: values.type,
    };
  };

  const handleSubmit = (values: Event) => {
    const newEvent = convertFormValues(values);
    if (event && event.id) {
      onUpdate({ ...newEvent, id: event.id } as Event);
    } else {
      onAdd(newEvent);
    }
  };
  const addOneHourIfNeeded = (date: Date) => {
    date.setHours(date.getHours() + 1);
    return date;
  };

  const [agTypeSting, setAgTypeSting] = useState("");
  const [agSitSting, setAgSitSting] = useState("");
  const [agNomeSting, setAgNomeSting] = useState("");

  const formik = useFormik({
    initialValues: {
      id: event?.id ? event.id : "",
      title: event ? event.title : "",
      hisId: hisId || null,
      description: event ? event.description : undefined,
      start: event ? new Date(event.start) : new Date(),
      end: event ? new Date(event.end) : addOneHourIfNeeded(new Date()),
      color: event ? event.color : "primary",
      type: event ? String(event.type) : "1",
      event_type: event?.event_type ? String(event.event_type) : undefined,
      event_situation: event?.event_situation
        ? String(event.event_situation)
        : undefined,
      codchave: event ? event.codchave : hisId || undefined,
      codchave_OBJ: event ? undefined : undefined,
      contact_id: event ? event.contact_id : undefined,
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t("common.validations.required")),
      event_type: Yup.string().required(t("common.validations.required")),
      event_situation: Yup.string().required(t("common.validations.required")),
      type: Yup.string().required(t("common.validations.required")),
      description: Yup.string().max(
        100,
        t("common.validations.max", { size: 100 })
      ),
      start: Yup.date().required(t("common.validations.required")),
      end: Yup.date().required(t("common.validations.required")),
      color: Yup.string(),
      codchave: Yup.string().when("type", {
        is: (type: string) => type === "1" || type === "3",
        then: Yup.string().required(t("common.validations.required")),
        otherwise: Yup.string(),
      }),
    }),
    //onSubmit: (values) => console.log(values),
    onSubmit: handleSubmit,
  });

  const myTheme = createTheme(corePtBr, dataGridPtBr);
  const [loading, isLoading] = useState(false);
  const [loadingCli, isLoadingCli] = useState(false);

  const [loadingType, isLoadingType] = useState(false);
  const [loadingSituation, isLoadingSituation] = useState(false);
  const [contact, setContact] = useState<ClientContactsType | null>(null);

  const [candidate, setCandidate] = useState<
    Pessoal | string | null | undefined
  >(null);
  const [optionsContacts, setOptions] = useState<readonly ClientContactsType[]>(
    []
  );
  const [optionsCandidates, setOptionsCandidates] = useState<Pessoal[]>([]);

  const [optionsType, setOptionsType] = useState<TipoAgType[]>([]);

  const [optionsSituation, setOptionsSituation] = useState<TipoSituationType[]>(
    []
  );

  async function buscarContatoPorCodigo(
    jsonData: ClientContactsType[]
  ): Promise<ClientContactsType | null> {
    for (const contato of jsonData) {
      if (contato.id === event?.contact_id) {
        return contato;
      }
    }
    return null;
  }
  let abortController = new AbortController();

  async function getCandidate(
    e?: React.SyntheticEvent<Element, globalThis.Event> | undefined | null,
    search?: Pessoal | string | undefined | null
  ) {
    e?.preventDefault();

    const filterValues = {
      //@ts-ignore
      search: e?.target.value || search || event?.codchave,
      sex: "0",
      estcivil: "0",
      cagos: [],
      instrucoes: "0",
      cidades: [],
      salario: "90000.00",
      cvatualizado_min: formatDateNull("", "yyyy-MM-dd"),
      cvatualizado_max: formatDateNull("", "yyyy-MM-dd"),
      entrevistados: false,
      com_veiculos: false,
      pri_emp: false,
      pcd: false,
    };
    isLoading(true);

    let result: Pessoal[];

    result = await findAllCandidates(filterValues);
    setOptionsCandidates(result as Pessoal[]);

    isLoading(false);
    formik.setFieldValue("codchave_OBJ", result[0]);
  }

  async function populateTipoAg(type: string) {
    isLoadingType(true);

    let result;

    if (type == "1") {
      result = await getTipoAgR();
    }
    if (type == "3") {
      result = await getTipoAgC();
    }
    setOptionsType(result as TipoAgType[]);
    isLoadingType(false);
  }

  async function populateSituationAg(type: string) {
    isLoadingSituation(true);

    let result;

    if (type == "1") {
      result = await getSituationAg("sitagR");
    }
    if (type == "3") {
      result = await getSituationAg("sitagC");
    }
    setOptionsSituation(result as TipoSituationType[]);
    isLoadingSituation(false);
  }

  useEffect(() => {
    getContacts(Number(codChave)).then(async (res) => {
      const searshContacts = await buscarContatoPorCodigo(res);
      console.log(searshContacts);
      setContact(searshContacts);
    });
    if (!event?.type) {
      if (hasPattern("clients")) {
        formik.setFieldValue("type", "3");
      } else if (hasPattern("vacancy")) {
        if (hisId) {
          formik.setFieldValue("type", "1");
        } else {
          formik.setFieldValue("type", "2");
        }
      } else if (hasPattern("candidate")) {
        formik.setFieldValue("type", "1");
      } else {
        formik.setFieldValue("type", "");
      }
    }
  }, []);

  const getContacts = async (id: number): Promise<any | boolean> => {
    const data = await getClientContacts(id);
    if (data as any) {
      setOptions(data as ClientContactsType[]);
      return data;
    }
    return false;
  };

  useEffect(() => {
    if (editMode) {
      if (formik.values.type == "1") {
        populateTipoAg("1");
        populateSituationAg("1");
      }
      if (formik.values.type == "3") {
        populateTipoAg("3");
        populateSituationAg("3");
      }
    } else {
      if (formik.values.type == "1") {
        populateTipoAg("1");
        populateSituationAg("1");
        formik.setFieldValue("event_type", "");
        formik.setFieldValue("event_situation", "");
      }
      if (formik.values.type == "3") {
        populateTipoAg("3");
        populateSituationAg("3");
        formik.setFieldValue("event_type", "");
        formik.setFieldValue("event_situation", "");
      }
    }
  }, [formik.values.type]);

  useEffect(() => {
    if (editMode && formik.values.codchave != undefined) {
      //@ts-ignore
      getCandidate(undefined, formik.values.codchave);
    }
    if (hisCanId && hisCanId >= 1) {
      getCandidate(undefined, hisCanId.toString());
    }
  }, [formik.values.codchave]);

  useEffect(() => {
    if (formik.values.type == "3") {
      getContacts(Number(formik.values.codchave));
      formik.setFieldValue("contact_id", undefined);
      setContact(null);
    }
  }, [formik.values.codchave]);

  function setTile(codchave: string, agtype: string, agsit: string) {
    formik.setFieldValue("title", codchave + " " + agtype + " " + agsit);
  }

  useEffect(() => {
    setTile(agNomeSting, agTypeSting, agSitSting);
  }, [agTypeSting, agSitSting, agNomeSting]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="event-dialog-title">
      <form onSubmit={formik.handleSubmit} noValidate>
        <DialogTitle id="event-dialog-title">
          {editMode
            ? t("calendar.modal.edit.title")
            : t("calendar.modal.add.title")}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {/*       <Grid item xs={12} md={12}>
              <TextField
                margin="normal"
                size="small"
                required
                fullWidth
                id="title"
                label={t("calendar.form.title.label")}
                name="title"
                autoFocus
                disabled={processing}
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
            </Grid> */}

            <Grid item xs={6} md={6}>
              <MobileDateTimePicker
                label={t("calendar.form.start.label")}
                className="tabsEdited"
                inputFormat="dd/MM/yyyy H:mm"
                value={formik.values.start}
                onChange={(date: Date | null) =>
                  formik.setFieldValue("start", date)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="start"
                    size="small"
                    variant="filled"
                    error={formik.touched.start && Boolean(formik.errors.start)}
                    disabled={processing}
                    fullWidth
                    margin="normal"
                    name="start"
                  />
                )}
              />
              {formik.touched.start && (
                <FormHelperText error>{formik.errors.start}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={6} md={6}>
              <MobileDateTimePicker
                label={t("calendar.form.end.label")}
                inputFormat="dd/MM/yyyy H:mm"
                value={formik.values.end}
                onChange={(date: Date | null) =>
                  formik.setFieldValue("end", date)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="end"
                    variant="filled"
                    size="small"
                    disabled={processing}
                    fullWidth
                    margin="normal"
                    name="end"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <FormControl component="fieldset" margin="normal">
                <FormLabel component="animate">Tipo da Agenda</FormLabel>
                <RadioGroup
                  row
                  aria-label="type"
                  name="type"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  defaultValue="first"
                >
                  {editMode ? (
                    <>
                      {eventColorsJson.map((item) => (
                        <>
                          {item.value === formik.values.type && (
                            <FormControlLabel
                              key={item.value}
                              value={item.value}
                              control={
                                <Radio
                                  size="small"
                                  disabled={processing}
                                  sx={{
                                    color: `${item.color}.main`,
                                    "&.Mui-checked": {
                                      color: `${item.color}.main`,
                                    },
                                  }}
                                />
                              }
                              label={item.label}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      {eventColorsJson.map((item) => (
                        <FormControlLabel
                          key={item.value}
                          value={item.value}
                          control={
                            <Radio
                              size="small"
                              disabled={processing}
                              sx={{
                                color: `${item.color}.main`,
                                "&.Mui-checked": {
                                  color: `${item.color}.main`,
                                },
                              }}
                            />
                          }
                          label={item.label}
                        />
                      ))}
                    </>
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <FormControl fullWidth style={{ marginTop: 16 }} variant="filled">
                <InputLabel
                  error={
                    formik.touched.event_type &&
                    Boolean(formik.errors.event_type)
                  }
                  shrink
                  id="event_type_label"
                >
                  Tipo da Agenda
                </InputLabel>
                <Select
                  labelId="event_type_label"
                  id="event_type"
                  variant="filled"
                  name="event_type"
                  size="small"
                  autoComplete="off"
                  disabled={loadingType}
                  label="Tipo da Agenda"
                  value={formik.values.event_type}
                  error={
                    formik.touched.event_type &&
                    Boolean(formik.errors.event_type)
                  }
                  onChange={formik.handleChange}
                  endAdornment={
                    loadingType && (
                      <InputAdornment position="end">
                        <CircularProgress size={13} />
                      </InputAdornment>
                    )
                  }
                >
                  {optionsType.map((item, i) => (
                    <MenuItem
                      key={i}
                      className="camelize"
                      value={`${item.codigo}`}
                      onClick={() => setAgTypeSting(item.descricao)}
                    >
                      {item.descricao}
                    </MenuItem>
                  ))}
                </Select>

                {formik.touched.event_type && (
                  <FormHelperText error>
                    {formik.errors.event_type}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <FormControl fullWidth style={{ marginTop: 16 }} variant="filled">
                <InputLabel
                  error={
                    formik.touched.event_situation &&
                    Boolean(formik.errors.event_situation)
                  }
                  shrink
                  id="event_situation_label"
                >
                  Situação da Agenda
                </InputLabel>
                <Select
                  labelId="event_situation_label"
                  id="event_situation"
                  name="event_situation"
                  size="small"
                  variant="filled"
                  autoComplete="off"
                  disabled={loadingSituation}
                  label="Tipo da Agenda"
                  value={formik.values.event_situation}
                  error={
                    formik.touched.event_situation &&
                    Boolean(formik.errors.event_situation)
                  }
                  onChange={formik.handleChange}
                  endAdornment={
                    loadingSituation && (
                      <InputAdornment position="end">
                        <CircularProgress size={13} />
                      </InputAdornment>
                    )
                  }
                >
                  {optionsSituation.map((item, i) => (
                    <MenuItem
                      key={i}
                      className="camelize"
                      value={`${item.codigo}`}
                      onClick={() => setAgSitSting(item.descricao)}
                    >
                      {item.descricao}
                    </MenuItem>
                  ))}
                </Select>

                {formik.touched.event_situation && (
                  <FormHelperText error>
                    {formik.errors.event_situation}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {formik.values.type == "1" && (
              <>
                {editMode ? (
                  <>
                    {!formik.values.codchave_OBJ && (
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          mt: 1,
                          mb: 1,
                        }}
                      >
                        <Typography>Procurando Candidato</Typography>
                        <CircularProgress sx={{ ml: 2 }} />
                      </Grid>
                    )}
                    {formik.values.codchave_OBJ && (
                      <Grid item xs={12} md={12} lg={12}>
                        <Autocomplete
                          id="codchave"
                          open={openAsyncCLients}
                          onOpen={() => {
                            setOpenAsyncClients(true);
                          }}
                          onClose={() => {
                            setOpenAsyncClients(false);
                          }}
                          value={formik.values.codchave_OBJ}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          getOptionLabel={(option) =>
                            `${option.id} - (${option.pessoal_nome}) - ${option.pessoal_cidade} em ${option.pessoal_bairro}`
                          }
                          options={optionsCandidates}
                          loading={loading}
                          onChange={(event: any, newValue: any | null) => {
                            if (newValue) {
                              formik.setFieldValue("codchave", newValue?.id);
                              formik.setFieldValue("codchave_OBJ", newValue);
                              setAgNomeSting(newValue?.pessoal_nome);
                            }
                          }}
                          onInputChange={(e) => {
                            getCandidate(e);
                          }}
                          renderOption={(props, option) => (
                            <Box
                              key={option.id}
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <Grid container>
                                <Grid item xs={12}>
                                  <b>{option.id}</b> {option.pessoal_nome}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  Cidade: <b>{option.pessoal_cidade}</b>
                                </Grid>
                                {option.pessoal_bairro && (
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    Bairro: <b>{option.pessoal_bairro}</b>
                                  </Grid>
                                )}
                              </Grid>
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Candidato"
                              placeholder="Digite para buscar..."
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              autoComplete="off"
                              variant="filled"
                              error={
                                formik.touched.codchave &&
                                Boolean(formik.errors.codchave)
                              }
                              fullWidth
                            />
                          )}
                        />
                        {formik.touched.codchave && (
                          <FormHelperText error>
                            {formik.errors.codchave}
                          </FormHelperText>
                        )}
                      </Grid>
                    )}
                  </>
                ) : (
                  <>
                    {hisCanId && hisCanId >= 1 ? (
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        sx={{
                          border: "1px solid #f3f3f3",
                          background: "#f3f3f3",
                          borderRadius: 1,
                          mt: 1,
                          ml: 1,
                        }}
                      >
                        <Typography variant="h5">Candidato</Typography>
                        <Typography>
                          {/* //@ts-ignore */}
                          {formik.values.codchave_OBJ ? (
                            //@ts-ignore
                            formik.values.codchave_OBJ.pessoal_nome
                          ) : (
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              sx={{
                                mt: 1,
                                mb: 1,
                              }}
                            >
                              <Typography>Procurando Candidato</Typography>
                              <CircularProgress sx={{ ml: 2 }} />
                            </Grid>
                          )}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={12} lg={12}>
                        <Autocomplete
                          id="codchave"
                          open={openAsyncCLients}
                          onOpen={() => {
                            setOpenAsyncClients(true);
                          }}
                          onClose={() => {
                            setOpenAsyncClients(false);
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          getOptionLabel={(option) =>
                            `${option.id} - (${option.pessoal_nome}) - ${option.pessoal_cidade} em ${option.pessoal_bairro}`
                          }
                          options={optionsCandidates}
                          loading={loading}
                          onChange={(event: any, newValue: any | null) => {
                            if (newValue) {
                              formik.setFieldValue("codchave", newValue?.id);
                              formik.setFieldValue("codchave_OBJ", newValue);
                              setAgNomeSting(newValue?.pessoal_nome);
                            }
                          }}
                          onInputChange={(e) => {
                            getCandidate(e);
                          }}
                          renderOption={(props, option) => (
                            <Box
                              key={option.id}
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <Grid container>
                                <Grid item xs={12}>
                                  <b>{option.id}</b> {option.pessoal_nome}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  Cidade: <b>{option.pessoal_cidade}</b>
                                </Grid>
                                {option.pessoal_bairro && (
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    Bairro: <b>{option.pessoal_bairro}</b>
                                  </Grid>
                                )}
                              </Grid>
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Candidato"
                              placeholder="Digite para buscar..."
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              autoComplete="off"
                              variant="filled"
                              error={
                                formik.touched.codchave &&
                                Boolean(formik.errors.codchave)
                              }
                              fullWidth
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                        {formik.touched.codchave && (
                          <FormHelperText error>
                            {formik.errors.codchave}
                          </FormHelperText>
                        )}
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}
            {formik.values.type == "3" && (
              <>
                {clientsSys && (
                  <Grid item xs={6} md={6} lg={6}>
                    <Autocomplete
                      id="codchave"
                      //@ts-ignore
                      value={compareClients(formik.values.codchave, clientsSys)}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) =>
                        `${option.id} - (${option.nome})`
                      }
                      options={clientsSys}
                      onChange={(event: any, newValue: ClientsType | null) => {
                        if (newValue) {
                          formik.setFieldValue("codchave", newValue?.id);
                          setAgNomeSting(newValue?.nome);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Cliente"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          autoComplete="off"
                          variant="filled"
                          error={
                            formik.touched.codchave &&
                            Boolean(formik.errors.codchave)
                          }
                          fullWidth
                        />
                      )}
                    />
                    {formik.touched.codchave && (
                      <FormHelperText error>
                        {formik.errors.codchave}
                      </FormHelperText>
                    )}
                  </Grid>
                )}

                <Grid item xs={6} md={6} lg={6}>
                  <Autocomplete
                    id="contact_id"
                    open={openAsyncCLients}
                    onOpen={() => {
                      setOpenAsyncClients(true);
                    }}
                    value={compareContactsAg(
                      formik.values.contact_id,
                      optionsContacts
                    )}
                    onClose={() => {
                      setOpenAsyncClients(false);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.codigo === value.codigo
                    }
                    getOptionLabel={(option) => option.contato}
                    options={optionsContacts}
                    onChange={(
                      event: any,
                      newValue: ClientContactsType | null
                    ) => {
                      if (newValue) {
                        formik.setFieldValue("contact_id", newValue?.id);
                        setContact(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Contato"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        autoComplete="off"
                        placeholder="Digite para buscar..."
                        variant="filled"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={12}>
              <TextField
                margin="normal"
                size="small"
                fullWidth
                id="description"
                label={t("calendar.form.description.label")}
                name="description"
                rows={4}
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                variant="filled"
                disabled={processing}
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {event && event.id && (
            <IconButton
              aria-label="delete event"
              onClick={() => onDelete(event.id)}
              disabled={processing}
            >
              <DeleteIcon />
            </IconButton>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Button onClick={onClose}>{t("common.cancel")}</Button>
          <LoadingButton loading={processing} type="submit" variant="contained">
            {editMode
              ? t("calendar.modal.edit.action")
              : t("calendar.modal.add.action")}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EventDialog;
