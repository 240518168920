import { TAutocompleteItem } from "mui-rte";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ClientsType,
  findAllClients,
  getClientInfo,
} from "../../admin/hooks/useClients";
import {
  CargosType,
  CepType,
  ClassificationType,
  EstCivilType,
  FormacoesType,
  FreqType,
  InstrucaoType,
  MotivosType,
  MunicipiosType,
  SineType,
  TipoAgType,
  TipoSituationType,
  TipoVagaType,
  TurnoType,
  UFsType,
  UnidadesType,
  UsersSysType,
  getCargosSys,
  getCep,
  getClassification,
  getEstCivilSys,
  getFormacoesSys,
  getFrequecesSys,
  getInstrucaoSys,
  getMotivoSys,
  getMunicipiosSys,
  getSineSys,
  getSituation,
  getTipoAg,
  getTipoVagaSys,
  getUFsSys,
  getUnidadesSys,
  getUsersSys,
  getUsersSysRich,
  updateF10,
  updateF10Type,
} from "../../admin/hooks/useUtils";
import { Pessoal } from "../../admin/hooks/@types/candidateTypes";
import { useSnackbar } from "./SnackbarProvider";
import { useReactToPrint } from "react-to-print";
//@ts-ignore

interface FunctionsContextInterface {
  loadingAdress: boolean;
  getAdress: () => void;
  clientAdress: ClientAddress | undefined;
  candidateAdress: ClientAddress | undefined;
  getInfoDb: (id: number) => Promise<void>;
  clientInfo: any;
  usersSys: UsersSysType[];
  usersSysRich: TAutocompleteItem[];
  handleF10: (
    id: number,
    colum: string,
    table: string,
    comment: string
  ) => Promise<void>;
  f10: updateF10Type | undefined;
  updateCliData: string;
  clientVacancy: number;
  setClientVacancy: (values: number) => void;
  setUpdateCliData: (values: string) => void;
  setUFVacancy: (values: UFsType | undefined) => void;
  frequencesSys: FreqType[];
  cargosSys: CargosType[];
  motivosSys: MotivosType[];
  tVagaSys: TipoVagaType[];
  sineSys: SineType[];
  unidadesSys: UnidadesType[];
  ufsSys: UFsType[];
  municipiosSys: MunicipiosType[];
  intrucaoSys: InstrucaoType[];
  classificationsSys: ClassificationType[];
  municipiosAllSys: MunicipiosType[];
  f10view: boolean;
  ufVacancy: UFsType | undefined;
  loadingMunicipios: boolean;
  clientsSys: ClientsType[];
  formacoesSys: FormacoesType[];
  turnoSys: TurnoType[];
  populateEstCivil: EstCivilType[];
  isLoadingPopulate: boolean;
  getFromLocalStorage: any;
  tipoAgSys: TipoAgType[];
  situationAgSys: TipoSituationType[];
  copyToClipboard: (text: string) => void;
  setRef: (ref: React.MutableRefObject<null>) => void;
  handlePrint: () => void;
}

export const FunctionsContext = createContext({} as FunctionsContextInterface);

type SnackbarProviderProps = {
  children: React.ReactNode;
};

export type ClientAddress = {
  bairro: String;
  cep: String;
  localidade: String;
  logradouro: String;
  uf: String;
};

export const genders = [
  { label: "Feminino", value: "F" },
  { label: "Masculino", value: "M" },
];

export const turnoSys: TurnoType[] = [
  { turno_value: "MANHA" },
  { turno_value: "TARDE" },
  { turno_value: "NOITE" },
  { turno_value: "EAD" },
  { turno_value: "SEMIPRESENCIAL" },
];

const FunctionsProvider = ({ children }: SnackbarProviderProps) => {
  const [loadingAdress, setIsLoadingAdress] = useState(false);
  const snackbar = useSnackbar();

  const [updateCliData, setUpdateCliData] = useState("");

  const [usersSys, setUsersSys] = useState<UsersSysType[]>([]);
  const [usersSysRich, setUsersSysRich] = useState<TAutocompleteItem[]>([]);

  const [f10, setF10Sys] = useState<updateF10Type>();
  const [f10view, setF10View] = React.useState<boolean>(true);

  const [clientAdress, setClientAdress] = useState<ClientAddress>();
  const [candidateAdress, setCandidateAdress] = useState<ClientAddress>();
  const [clientInfo, setClientInfo] = useState();

  //Vacancy
  const [ufVacancy, setUFVacancy] = useState<UFsType | undefined>();
  const [loadingMunicipios, setIsLoadingMunicipios] = useState(false);
  const [clientVacancy, setClientVacancy] = useState(0);

  const getInfoDb = async (id: number): Promise<any | boolean> => {
    const data = await getClientInfo(id);
    if (data as any) {
      setClientInfo(data);
      return data;
    }
    return false;
  };

  const [isLoadingPopulate, setIsLoadingPopulate] = useState(true);

  const getAdress = async () => {
    const cepInput = document.querySelector<HTMLInputElement>(
      "#cep , #pessoal_cep"
    );

    const logradouroInput =
      document.querySelector<HTMLInputElement>("#endereco");
    const bairroInput = document.querySelector<HTMLInputElement>("#bairro");
    const localidadeInput = document.querySelector<HTMLInputElement>("#cidade");
    const ufInput = document.querySelector<HTMLInputElement>("#uf");

    if (cepInput && cepInput.value.length >= 9) {
      setIsLoadingAdress(true);

      try {
        const cep = cepInput.value.split(/[-.]/).join("");
        const response = await getCep(cep);

        if (response.erro) {
          throw new Error("CEP not found");
        }

        console.log(
          logradouroInput?.value,
          bairroInput?.value,
          localidadeInput?.value,
          ufInput?.value
        );

        if (response as CepType) {
          if (logradouroInput && bairroInput && localidadeInput && ufInput) {
            logradouroInput.value = response?.logradouro;
            bairroInput.value = response?.bairro;
            localidadeInput.value = response?.localidade;
            ufInput.value = response?.uf;
            console.log(cepInput.id);
            if (cepInput.id == "pessoal_cep") {
              setCandidateAdress({
                cep: response?.cep,
                logradouro: response?.logradouro,
                bairro: response?.bairro,
                localidade: response?.localidade,
                uf: response?.uf,
              });
            } else {
              setClientAdress({
                cep: response?.cep,
                logradouro: response?.logradouro,
                bairro: response?.bairro,
                localidade: response?.localidade,
                uf: response?.uf,
              });
            }
          }
        }
      } catch (error) {
        console.error("Error fetching CEP:", error);
      } finally {
        setIsLoadingAdress(false);
      }
    }
  };

  const saveToLocalStorage = (data: any, key: string) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  const getFromLocalStorage = (key: string): any[] => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : [];
  };

  const getUsers = async () => {
    getUsersSys().then((res) => {
      saveToLocalStorage(res, "usersSys");
      setUsersSys(res as UsersSysType[]);
    });
  };

  const getUsersRich = async () => {
    getUsersSysRich().then((res) => {
      saveToLocalStorage(res, "usersSysRich");
      setUsersSysRich(res as TAutocompleteItem[]);
    });
  };

  const [frequencesSys, setFrequencesSys] = useState<FreqType[]>([]);

  const getFrequeces = async () => {
    getFrequecesSys().then((res) => {
      saveToLocalStorage(res, "frequencesSys");
      setFrequencesSys(res as FreqType[]);
    });
  };

  const [cargosSys, setCargosSys] = useState<CargosType[]>([]);

  const getCargos = async () => {
    getCargosSys().then((res) => {
      setCargosSys(res as CargosType[]);
      saveToLocalStorage(res, "cargosSys");
    });
  };

  const [motivosSys, setMotivosSys] = useState<MotivosType[]>([]);

  const getMotivos = async () => {
    getMotivoSys().then((res) => {
      saveToLocalStorage(res, "motivosSys");
      setMotivosSys(res as MotivosType[]);
    });
  };

  const [tVagaSys, setTVagaSys] = useState<TipoVagaType[]>([]);

  const getTVaga = async () => {
    getTipoVagaSys().then((res) => {
      saveToLocalStorage(res, "tVagaSys");
      setTVagaSys(res as TipoVagaType[]);
    });
  };

  const [sineSys, setSineSys] = useState<SineType[]>([]);

  const getSine = async () => {
    getSineSys().then((res) => {
      saveToLocalStorage(res, "sineSys");
      setSineSys(res as SineType[]);
    });
  };

  const [unidadesSys, setUnidadesSys] = useState<UnidadesType[]>([]);

  const getUnidades = async () => {
    getUnidadesSys().then((res) => {
      saveToLocalStorage(res, "unidadesSys");
      setUnidadesSys(res as UnidadesType[]);
    });
  };

  const [classificationsSys, setClassificationssSys] = useState<
    ClassificationType[]
  >([]);

  const getClassifications = async () => {
    getClassification().then((res) => {
      saveToLocalStorage(res, "classificationsSys");
      setClassificationssSys(res as ClassificationType[]);
    });
  };

  const [intrucaoSys, setInstrucaoSys] = useState<InstrucaoType[]>([]);

  const getInstrucao = async () => {
    getInstrucaoSys().then((res) => {
      saveToLocalStorage(res, "intrucaoSys");
      setInstrucaoSys(res as InstrucaoType[]);
    });
  };

  const [municipiosSys, setMunicipiosSys] = useState<MunicipiosType[]>([]);

  const getMunicipios = async () => {
    setIsLoadingMunicipios(true);

    await getMunicipiosSys(ufVacancy && ufVacancy.codigo).then((res) => {
      setMunicipiosSys(res as MunicipiosType[]);
      saveToLocalStorage(res, "municipiosSys");
    });

    setIsLoadingMunicipios(false);
  };

  const [municipiosAllSys, setMunicipiosAllSys] = useState<MunicipiosType[]>(
    []
  );

  const getMunicipiosAll = async () => {
    await getMunicipiosSys().then((res) => {
      saveToLocalStorage(res, "municipiosAllSys");
      setMunicipiosAllSys(res as MunicipiosType[]);
    });
  };

  const [clientsSys, setClientsSys] = useState<any[]>([]);

  const getClientsAll = async () => {
    await findAllClients().then((res) => {
      saveToLocalStorage(res, "clientsSys");
      setClientsSys(res as any[]);
    });
  };

  const [ufsSys, setUFsSys] = useState<UFsType[]>([]);

  const getUFs = async () => {
    getUFsSys().then((res) => {
      saveToLocalStorage(res, "ufsSys");
      setUFsSys(res as UFsType[]);
    });
  };

  const [tipoAgSys, setTipoAgSys] = useState<TipoAgType[]>([]);

  const getTipoAgSys = async () => {
    getTipoAg().then((res) => {
      saveToLocalStorage(res, "tipoAgSys");
      setTipoAgSys(res as TipoAgType[]);
    });
  };

  const [situationAgSys, setSituationAgSys] = useState<TipoSituationType[]>([]);

  const getSituationAgSys = async () => {
    getSituation().then((res) => {
      saveToLocalStorage(res, "situationAgSys");
      setSituationAgSys(res as TipoSituationType[]);
    });
  };

  const [formacoesSys, setFormacoesSys] = useState<FormacoesType[]>([]);

  const getFormacoes = async () => {
    getFormacoesSys().then((res) => {
      saveToLocalStorage(res, "formacoesSys");
      setFormacoesSys(res as FormacoesType[]);
    });
  };

  const handleF10 = async (
    id: number,
    colum: string,
    table: string,
    comment: string
  ) => {
    setF10View(false);
    updateF10(id, comment, colum, table)
      .then((res) => {
        setF10Sys(res as updateF10Type);
      })
      .finally(() => setF10View(true));
  };

  const [populateEstCivil, setPopulateEstCivil] = useState<EstCivilType[]>([]);

  const getEstCivil = async () => {
    setIsLoadingPopulate(true);
    getEstCivilSys().then((res) => {
      saveToLocalStorage(res, "populateEstCivil");
      setPopulateEstCivil(res as EstCivilType[]);
    });
    setIsLoadingPopulate(false);
  };

  useEffect(() => {
    getMunicipiosAll();
    getClientsAll();
    getUsers();
    getInstrucao();
    getMunicipios();
    getTVaga();
    getFrequeces();
    getCargos();
    getUnidades();
    getMotivos();
    getClassifications();
    getUsersRich();
    getUFs();
    getEstCivil();
    getFormacoes();
    getSine();
    getTipoAgSys();
    getSituationAgSys();
  }, []);

  useEffect(() => {
    getMunicipios();
  }, [ufVacancy]);

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      snackbar.success("Link copiado");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const [contentToPrint, setContentToprint] = useState<
    React.MutableRefObject<null>
  >(useRef(null));

  const setRef = (ref: React.MutableRefObject<null>) => {
    console.log(ref);
    setContentToprint(ref);
  };

  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
  });

  return (
    <FunctionsContext.Provider
      value={{
        loadingAdress,
        getAdress,
        clientAdress,
        candidateAdress,
        getInfoDb,
        clientInfo,
        turnoSys,
        usersSys,
        usersSysRich,
        handleF10,
        f10,
        updateCliData,
        setUpdateCliData,
        frequencesSys,
        cargosSys,
        motivosSys,
        tVagaSys,
        unidadesSys,
        municipiosSys,
        ufsSys,
        sineSys,
        intrucaoSys,
        classificationsSys,
        f10view,
        ufVacancy,
        clientsSys,
        setUFVacancy,
        loadingMunicipios,
        municipiosAllSys,
        clientVacancy,
        populateEstCivil,
        formacoesSys,
        setClientVacancy,
        isLoadingPopulate,
        getFromLocalStorage,
        copyToClipboard,
        setRef,
        handlePrint,
        tipoAgSys,
        situationAgSys,
      }}
    >
      {children}
    </FunctionsContext.Provider>
  );
};

export function useFunctions() {
  return useContext(FunctionsContext);
}

export default FunctionsProvider;
