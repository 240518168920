import { format } from "date-fns";
import api from "../infra/api";
import { getUser } from "../infra/getUser";
import { TAutocompleteItem } from "mui-rte";

export type CepType = {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
};

export type ClassificationType = {
  id: number;
  descricao: string;
};

export type ProposalStatusType = {
  codproposta_codigo: number;
  codproposta_descricao: string;
  codproposta_fechada?: number;
};

export type ContractStatusType = {
  codcontrato_codigo: number;
  codcontrato_descricao: string;
  codcontrato_ativo?: number;
};

export type SineType = {
  sine_codigo: number;
  sine_descricao: string;
};

export type ContractTipoType = {
  id: number;
  valor: string;
};

export type UsersSysType = {
  id: string;
  nome: string;
  nomecomp: string;
};

export type UsersSysRichType = {
  keys: string;
  value: string;
  content: string;
};

export type FreqType = {
  freqagenda_cod: number;
  freqagenda_descricao: string;
};

export type FormacoesType = {
  formacoes_codigo: number;
  formacoes_descricao: string;
};

export type TurnoType = {
  turno_value: string;
};

export type CargosType = {
  codigo: string;
  codigo_id: number;
  nome: string;
};

export type MotivosType = {
  codigo: number;
  descricao: string;
};

export type TipoVagaType = {
  codigo: number;
  tipovaga_codigo: string;
  tipovaga_descricao: string;
};

export type InstrucaoType = {
  ordem_busca: number;
  instrucao_descricao: string;
  codigo: string;
  instrucao_codigo: string;
};

export type UnidadesType = {
  unidade_codigo: number;
  unidade_descricao: string;
};

export type UFsType = {
  codigo: string;
  valor: string;
};

export type MunicipiosType = {
  id: number;
  codigo: number;
  nome: string;
  uf: string;
};

export type updateF10Type = {
  id: number;
  newComment: string;
};

export type UploadProposalType = {
  id: number | null;
  name: string | null;
};

export type GetFileType = {
  id: number;
  url: string;
};

export type TipoAgType = {
  codigo: number;
  descricao: string;
};

export type TipoSituationType = {
  codigo: number;
  descricao: string;
};

export type PhoneType = {
  telefone_codigo: number;
  telefone_ddd: string;
  telefone_tipo: string;
  telefone_ddi: string;
  telefone_numero: string;
  telefone_codchave: number;
  telefone_chave: string;
  telefone_ramal: string;
  telefone_contato: string;
  telefone_depto: string;
  telefone_assunto: string;
  telefone_operadora: string;
  whatsapp: any;
};

export const getCep = async (cep: string): Promise<boolean | any | CepType> => {
  const cepEndpoint =
    process.env.REACT_APP_API_URL + `api/utils/cep?cep=${cep}`;
  try {
    const response = await api(cepEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as CepType;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getFromLocalStorage = (key: string): any[] => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : [];
};

export const getClassification = async (): Promise<
  ClassificationType[] | boolean
> => {
  const cepEndpoint =
    process.env.REACT_APP_API_URL + `api/utils/populate?label=classificacao`;
  try {
    const response = await api(cepEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as ClassificationType[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getSituationAg = async (
  type: string
): Promise<TipoSituationType[] | boolean> => {
  const cepEndpoint =
    process.env.REACT_APP_API_URL + `api/utils/populate?label=${type}`;
  try {
    const response = await api(cepEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as TipoAgType[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getSituation = async (): Promise<
  TipoSituationType[] | boolean
> => {
  const cepEndpoint =
    process.env.REACT_APP_API_URL + `api/utils/populate?label=sitag`;
  try {
    const response = await api(cepEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as TipoAgType[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getTipoAgC = async (): Promise<TipoAgType[] | boolean> => {
  const cepEndpoint =
    process.env.REACT_APP_API_URL + `api/utils/populate?label=tipoagC`;
  try {
    const response = await api(cepEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as TipoAgType[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getTipoAg = async (): Promise<TipoAgType[] | boolean> => {
  const cepEndpoint =
    process.env.REACT_APP_API_URL + `api/utils/populate?label=tipoag`;
  try {
    const response = await api(cepEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as TipoAgType[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getTipoAgR = async (): Promise<TipoAgType[] | boolean> => {
  const cepEndpoint =
    process.env.REACT_APP_API_URL + `api/utils/populate?label=tipoagR`;
  try {
    const response = await api(cepEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as TipoAgType[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getUsersSys = async (): Promise<UsersSysType[] | boolean> => {
  const cepEndpoint =
    process.env.REACT_APP_API_URL + `api/utils/populate?label=usuarios`;
  try {
    const response = await api(cepEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as UsersSysType[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getFormacoesSys = async (): Promise<FormacoesType[] | boolean> => {
  const endpoint =
    process.env.REACT_APP_API_URL + `api/utils/populate?label=formacoes`;
  try {
    const response = await api(endpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as FormacoesType[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getUsersSysRich = async (): Promise<
  TAutocompleteItem[] | boolean
> => {
  const cepEndpoint =
    process.env.REACT_APP_API_URL + `api/utils/populate?label=usuariosRich`;
  try {
    const response = await api(cepEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as TAutocompleteItem[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getFrequecesSys = async (): Promise<FreqType[] | boolean> => {
  const cepEndpoint =
    process.env.REACT_APP_API_URL + `api/utils/populate?label=freqagenda`;
  try {
    const response = await api(cepEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as FreqType[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getCargosSys = async (): Promise<CargosType[] | boolean> => {
  const cepEndpoint =
    process.env.REACT_APP_API_URL + `api/utils/populate?label=cargo`;
  try {
    const response = await api(cepEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as CargosType[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getUFsSys = async (): Promise<UFsType[] | boolean> => {
  const cepEndpoint =
    process.env.REACT_APP_API_URL + `api/utils/populate?label=uf`;
  try {
    const response = await api(cepEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as UFsType[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export type EstCivilType = {
  estcivil_codigo: number;
  estcivil_descricao: string;
};

export const getEstCivilSys = async (): Promise<EstCivilType[] | boolean> => {
  const cepEndpoint =
    process.env.REACT_APP_API_URL + `api/utils/populate?label=estcivil`;
  try {
    const response = await api(cepEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as EstCivilType[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getMunicipiosSys = async (
  uf?: string
): Promise<MunicipiosType[] | boolean> => {
  let cepEndpoint =
    process.env.REACT_APP_API_URL +
    `api/utils/populate?label=municipio` +
    `&addon=${uf}`;
  switch (uf) {
    case undefined:
      cepEndpoint =
        process.env.REACT_APP_API_URL + `api/utils/populate?label=municipio`;
      break;
  }

  try {
    const response = await api(cepEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as MunicipiosType[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getMotivoSys = async (): Promise<MotivosType[] | boolean> => {
  const cepEndpoint =
    process.env.REACT_APP_API_URL + `api/utils/populate?label=motivocanc`;
  try {
    const response = await api(cepEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as MotivosType[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getTipoVagaSys = async (): Promise<TipoVagaType[] | boolean> => {
  const cepEndpoint =
    process.env.REACT_APP_API_URL + `api/utils/populate?label=tipovaga`;
  try {
    const response = await api(cepEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as TipoVagaType[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getUnidadesSys = async (): Promise<UnidadesType[] | boolean> => {
  const cepEndpoint =
    process.env.REACT_APP_API_URL + `api/utils/populate?label=unidade`;
  try {
    const response = await api(cepEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as UnidadesType[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getProposalStatusSys = async (): Promise<
  ProposalStatusType[] | boolean
> => {
  const cepEndpoint =
    process.env.REACT_APP_API_URL + `api/utils/populate?label=codproposta`;
  try {
    const response = await api(cepEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as ProposalStatusType[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getSineSys = async (): Promise<SineType[] | boolean> => {
  const cepEndpoint =
    process.env.REACT_APP_API_URL + `api/utils/populate?label=sine`;
  try {
    const response = await api(cepEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as SineType[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getContractsStatusSys = async (): Promise<
  ContractStatusType[] | boolean
> => {
  const cepEndpoint =
    process.env.REACT_APP_API_URL + `api/utils/populate?label=codcontrato`;
  try {
    const response = await api(cepEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as ContractStatusType[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getInstrucaoSys = async (): Promise<InstrucaoType[] | boolean> => {
  const endpoint =
    process.env.REACT_APP_API_URL + `api/utils/populate?label=instrucao`;
  try {
    const response = await api(endpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as InstrucaoType[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getContractsTypesSys = async (): Promise<
  ContractTipoType[] | boolean
> => {
  const cepEndpoint =
    process.env.REACT_APP_API_URL + `api/utils/populate?label=tcontrato`;
  try {
    const response = await api(cepEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as ContractTipoType[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const updateF10 = async (
  id: number,
  comment: string,
  colum: string,
  table: string
): Promise<updateF10Type | undefined | boolean> => {
  if (!comment) {
    return false;
  }
  const url = process.env.REACT_APP_API_URL + `api/utils/f10/` + id;
  const { user } = getUser();

  const now = new Date();

  const formattedDateTime = format(now, "dd/MM/yyyy HH:mm");
  try {
    const response = await api(url, {
      method: "PUT",
      body: JSON.stringify({
        colum: colum,
        comment: `(${user} - ${formattedDateTime}) - ${comment}`,
        table: table,
      }),
      mode: "cors",
      headers: {
        "content-Type": "application/json",
      },
    });

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as updateF10Type;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getFile = async (id: number): Promise<GetFileType | boolean> => {
  const fileEndpoint = process.env.REACT_APP_API_URL + `api/upload/` + id;
  try {
    const response = await api(fileEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as GetFileType;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const uploadProposal = async (
  file: File,
  path: string
): Promise<UploadProposalType | boolean> => {
  const url = process.env.REACT_APP_API_URL + `api/upload`;

  let formData = new FormData();
  formData.append("file", file);
  formData.append("pathS3", path);

  try {
    const response = await api(url, {
      method: "POST",
      body: formData,
    });

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as UploadProposalType;
  } catch (err) {
    console.log(err);
    return false;
  }
};
